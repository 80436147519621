*,
::before,
::after {
    box-sizing: border-box;
}

:root {
    scroll-behavior: smooth;
}


// override of default gist styling:

body .gist .gist-file {
    margin-bottom: 0;
    border: 1px solid #adb5bd;
    border-radius: 5px;
}

body .gist .gist-data {
    border-bottom: none;
    border-radius: 0;
    background-color: $c-primary-background;
    color: $c-text-light;
}

body .gist .blob-wrapper {
    border-radius: 0;
}

body .gist .highlight {
    background-color: transparent;
    font-family: 'Rubik', monospace;
    font-size: 14px;
    
}

body .gist .highlight td {
    padding: 5px 15px !important;
    line-height: 1;
    font-family: inherit;
    font-size: inherit;
    // color: $c-text-light; //override of default font color
}



body .gist tr:first-child td {
    padding-top: 15px !important;
}

body .gist tr:last-child td {
    padding-bottom: 15px !important;
}

body .gist .blob-num {
    color: #ced4da;
    background-color: #495057;
    pointer-events: none;
}

body .gist .gist-meta {
    display: none;
}