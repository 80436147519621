.profile-banner {
    background-color: $c-tertiary;
}

.profile-banner__container {
    max-width: $max-container-width;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
   
    img {
        max-height: 350px;
        vertical-align: bottom;
    }

    @media #{$media-md} {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.profile-banner__photo-container {
    margin-top: -20px;
    box-shadow: 0px 0px 5px $c-primary-other;

    @media #{$media-md} {
        margin-top: 0px;
    }
}

.profile-banner__text-container {
    padding: 30px 30px 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media #{$media-md} {
        padding: 30px 30px 30px 30px;
    }

    :nth-child(3) {
        margin-bottom: 20px;
    }

    h4 {
        margin: 0;
        font-weight: bold;
        color: $c-primary-other;
        text-align: left;
    }
}

.profile-banner__buttons {
    margin-top: 10px;
}

.my-story {
    background-color: $c-primary-background;
}

.my-story__container {
    max-width: $max-container-width;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    // padding: 0 15px;

    @media only screen and (min-width: 900px) {
        padding-right: 200px;
    }
}

.my-story__heading {
    text-transform: uppercase;
    color: $c-tertiary;

    ::after {
        display: block;
        content: '';
        border-bottom: 2.5px solid $c-tertiary;
        margin: 15px 0;
        width: 40px;
    }
}

.my-story__text {
    font-family: $f-text;
    font-size: 17px;
    color: $c-text-light;
    text-align: left;
    padding-bottom: 40px;

    @media only screen and (min-width: 900px) {
        padding-right: 40px;
        padding-bottom: 60px;
        font-size: 20px;
    }

    @media only screen and (min-width: 1100px) {
        text-align: justify;
        padding-right: 200px;
    }
}

// .quote {
//     padding: 10px 40px;
    

//     &::after {
//         display: block;
//         content: '';
//         border-left: 3px solid $c-tertiary;
//         margin-left: 15px;
//         height: 80%;
//         width: 100%;
//     }
// }

.my-story__date {
    margin-top: 50px;
    font-size: 16px;
}