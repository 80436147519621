.highlights {
    background-color: $c-primary-background;
    border-top: 2.5px solid $c-secondary-dark;
}

.highlights__container {
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 40px 15px;
    display: flex;
    flex-direction: column;
}

.highlights__heading {
    text-transform: uppercase;
    color: $c-secondary;

    :after {
        display: block;
        content: '';
        border-bottom: 2.5px solid $c-secondary;
        margin: 20px 0;
        width: 50px;
    }
}

.highlights__text {
    font-family: $f-text;
    font-size: 16px;
    color: $c-text-light;

    p {
        margin: 0 0 20px 0;
    }

    @media #{$media-md} {
        font-size: 20px;
    }
}

.highlights-highlight {
    color: $c-secondary;
}

.highlights__buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 400px;
    justify-content: space-between;
    padding-left: 0;

    .google-play,
    .button--primary {
        margin: 10px 0;

        @media #{$media-md} {
            margin: 0;
        }
    }
}

.highlights__container2 {

    @media #{$media-lg} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    }
}


.highlights__section1,
.highlights__section3 {
    padding: 15px;
    background-color: rgb(69, 69, 69);
    box-shadow: 0px 0px 8px rgb(24, 24, 24);
    margin-bottom: 18px;

    @media #{$media-lg} {
    flex-basis: 49.2%;
    }
}

.highlights__section2 a,
.highlights__section4 a{
    text-decoration: none;
    color: $c-text-light;

    &:hover,
    &:focus {
        color: $c-secondary;
    }
}

.highlights__section2,
.highlights__section4 {
    background-color: rgb(69, 69, 69);
    box-shadow: 0px 0px 8px rgb(24, 24, 24);
    padding: 15px;
    flex-basis: 49.2%;
    margin-bottom: 18px;

    // @media #{$media-md} {
    //     display: flex;
    //     align-items: flex-start;
    //     flex-wrap: wrap;
    // }

    h5 {
    color: $c-secondary;
    text-transform: uppercase;
    font-weight: bold;
    }
}

.highlights__section2-list-container,
.highlights__section4-list-container {
    list-style-type: none;
    padding-left: 0px;
}

.highlights__section2-list-item,
.highlights__section4-list-item {
    margin: 0;
    
    list-style-type: none;
}

.highlights__blogpost-heading {
    margin-right: 10px;
}
