.button {
    display: inline-block;
    font-family: $f-text;
    font-size: 22px;
    // padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;

    a {
    text-decoration: none;
    color: $c-text-light;
    display: inline-block;
    padding: 10px 20px;
    }

    a:hover,
    a:active {
        color: $c-primary-other;
    }
}

.button--primary {
    background-color: transparent;
    color: $c-text-light;
    border: 1.5px solid $c-text-light;

    &:hover {
        background-color: $c-secondary;
        // color: $c-primary-other;
        border: 1px solid $c-secondary;
        box-shadow: 0px 0px 9px $c-text-dark;
        // transform: translateY(-2px);
    }
}

.button--secondary {
    background-color: transparent;
    color: $c-text-light;
    border: 1.5px solid $c-text-light;

    &:hover,
    &:focus {
        background-color: $c-tertiary;
        // color: $c-primary-other;
        border: 1px solid $c-tertiary;
        box-shadow: 0px 0px 9px $c-text-dark;
        // transform: translateY(-2px);
    }
}

.button--tertiary1 {
    background-color: transparent;
    color: $c-text-light;
    border: 1.5px solid $c-text-light;
    font-size: 16px;
    
    a {
        padding: 6px 12px;
    }
    
    &:hover,
    &:focus {
        background-color: $c-secondary;
        border: 1px solid $c-secondary;
        box-shadow: 0px 0px 9px $c-text-dark;
    }
}

.button--tertiary2 {
    background-color: transparent;
    color: $c-text-light;
    border: 1.5px solid $c-text-light;
    font-size: 16px;
    
    a {
        padding: 8px 16px;
    }

    &:hover,
    &:focus {
        background-color: $c-tertiary;
        border: 1px solid $c-tertiary;
        box-shadow: 0px 0px 9px $c-text-dark;
       
    }
}

.button--tertiary3 {
    background-color: transparent;
    color: $c-primary-other;
    border: 2px solid $c-primary-other;
    font-size: 16px;
    font-weight: bold;
    
    a {
        padding: 8px 16px;
        color: $c-primary-other;
    }

    &:hover,
    &:focus {
        background-color: $c-tertiary;
        border: 1px solid $c-tertiary;
        box-shadow: 0px 0px 9px $c-text-dark;

        a {
            color: $c-text-light;
        }
    }
}

.google-play {
    display: block;

    &:hover,
    &:focus {
        box-shadow: 0px 0px 9px $c-text-dark;
    }
}

.back-to-top__container {
    max-width: $max-container-width;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 20px;
    padding-right: 15px;
    display: flex;
    justify-content: flex-end;
}

.blogpost-button {
    margin-bottom: 10px;
}

.button-subscription {
    display: inline-block;
    font-family: $f-text;
    padding: 10px 20px;
    background-color: transparent;
    color: $c-text-light;
    border: 1.5px solid $c-text-light;
    font-size: 16px;
    cursor: pointer;

    &:hover,
    &:active {
        color: $c-text-dark;
        background-color: $c-tertiary;
        border: 1px solid $c-tertiary;
        box-shadow: 0px 0px 9px $c-text-dark;
    }
}