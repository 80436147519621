

//    font-family: 'Rubik', sans-serif;
//    font-family: 'IBM Plex Sans', sans-serif;
//    font-family: 'Alegreya Sans', sans-serif;

body {
    font-family: $f-text;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $f-heading;
}

h1 {
    font-size: 50px;
    font-weight: 400;

    @media #{$media-md} {
        font-size: 62px;
    }

    @media #{$media-lg} {
        font-size: 75px;
    }
}

h2 {
    font-size: 28px;
    font-weight: 400;

    @media #{$media-md} {
        font-size: 36px;
    }

    @media #{$media-lg} {
        font-size: 40px;
    }
}

h3 {
    font-size: 20px;
    font-weight: 400;

    @media #{$media-md} {
        font-size: 24px;
    }

    @media #{$media-lg} {
        font-size: 28px;
    }
}

h4 {
    font-size: 18px;
    font-weight: 400;
    
    @media #{$media-md} {
        font-size: 20px;
    }
    
    @media #{$media-lg} {
            font-size: 26px;
    }
}

h5 {
    font-size: 16px;
    font-weight: 400;
    
    @media #{$media-md} {
        font-size: 18px;
    }
    
    @media #{$media-lg} {
        font-size: 19px;
    }
}

// .heading-secondary {
//     font-size: 24px;
//     color: $c-headings;

//     span {
//         position: relative;
//     }

//     span::after {
//         content:'';
//         display: inline-block;
//         width: 40px;
//         height: 1.5px;
//         background-color: $c-headings;
//         position: absolute;
//         bottom: 0;
//         left: 0;
    
//     }

//     @media #{$media-lg} {
//         font-size: 40px;
//     }
// }

