$c-primary-background: #393939;
$c-primary-other: #393939; //same colour, but defined in a different variable in case it needs to be changed separately from the background color//
$c-secondary: #3ED4F2;
$c-secondary-dark: #017D96;
$c-tertiary: #E15B15;
$c-tertiary-dark: #AF4711;
$c-text-light: #E9E9E9;
$c-text-mid-dark: #CCCCCC;
$c-text-dark: #171717;

$f-text: 'Rubik', 'sans-serif';
$f-heading: 'IBM Plex Sans', 'sans-serif';
$f-tagline: 'Alegreya', 'sans-serif';


$max-container-width: 1180px;

$media-md: "only screen and (min-width: 700px)";
$media-lg: "only screen and (min-width: 1181px)";