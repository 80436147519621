.img-responsive {
    width: 100%;
    height: auto;
}

.mt0 {
    margin-top: 0 !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mb0 {
    margin-bottom: 0 !important;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.intro__special-heading {
    color: transparent;
    font-size: 1px;
    text-align: center;
}