.navbar {
    background-color: $c-primary-background;
}

.navbar__left-side {
    color: $c-tertiary;
    font-family: $f-heading;
    // font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none;
        color: $c-tertiary;
    }
}

.navbar__left-side--icons {
    display: flex;
    max-width: 150px;
    justify-content: center;
    align-items: center;

    img {
        margin-right: 10px;
    }
}

.navbar__container {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: $max-container-width;
    margin: 0 auto;
}

.navbar__left-side {
    height: 40px;

    @media #{$media-md} {
        height: 50px;
    }
}

.navbar__toggler-check {
    display: none;
}

// this makes the menu for mobiles clickable
.navbar__toggler {
    display: inline-block;

    @media #{$media-md} {
        display: none;
    }

    span {
        display: block;
        width: 25px;
        height: 3px;
        background-color: $c-secondary;
        margin-bottom: 3px;
    }
}

.navbar__menu {
    display: none;
    list-style: none;
    width: 100%;
    padding: 0;

    @media #{$media-md} {
            display: flex;
            flex-direction: row;
            width: auto;
            margin: 0;
            align-items: center;
    }
}

// this assigns the blue triangle to the active menu item (only when the screen size is bigger than 700px). the triangle is svg file, so if the colour of the active menu link was to change, the svg file needs to be edited separately
.navbar__menu-item--active {
    .navbar__menu-link {
        color: $c-secondary;
        position: relative;

        &::after {
            display: none;
            content: '';
            background: url('/assets/images/icons/blue_triangle.svg') no-repeat;
            margin: 0px auto;
            padding: 0px 10px;
            width: 18px;
            height: 14px;
            position: absolute;
            top: 42px;
            left: 38%;
            align-items: center;
            justify-content: center;
            align-self: center;

            @media #{$media-md} {
                display: flex;
            }
        }
    }
}

// this class is assigned to submenu items to override the class 'navbar__menu-item--active' because that one has the blue triangle ::after pseudoelement
.navbar__menu-item--active2 {
    color: $c-secondary;

    .navbar__menu-link {
        &::after {
        display: none !important;
        }
    }
}

.navbar__menu-item {
    position: relative;
}

.navbar__menu-link {
    font-family: $f-heading;
    font-size: 16px;
    color: $c-text-light;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 15px;
    display: block;

    &:hover,
    &:focus,
    &:active {
        color: $c-secondary;
    }

    @media #{$media-md} {
        font-size: 18px;
    }
}


.navbar__toggler-check:checked ~ .navbar__menu {
    display: block;

    // this makes the menu go back to row if the size of the screen enlarges, even if the toggler is still checked. otherwise the menu items would still be in column
    @media #{$media-md} {
        display: flex;
        flex-direction: row;
    }
}

// this transforms the hamburger  menu into cross when the toggler is checked
.navbar__toggler-check:checked ~ .navbar__toggler {
    span:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
    }

    span:nth-child(2) {
        opacity: 0;
    }

    span:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
    }
}

.navbar__submenu {
    display: none;
    position: absolute;
    top: 0%;
    left: 130px;
    background: $c-primary-background;
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

// this makes the submenu items be displayed on the right side next to the main menu, so that the items don't overlap
.navbar__menu-item:hover .navbar__submenu {
    

    @media #{$media-md} {
        display: block;
        left: 0px;
        top: 100%;
        }
}