.contact-banner {
    background-color: $c-secondary;
}

.contact-banner__container {
    max-width: $max-container-width;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @media only screen and (min-width: 330px) {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.contact-banner__photo-container {
    margin-left: 28px;
    margin-right: 28px;

    img {
        // box-shadow: 0px 0px 4px $c-primary-other;
        border: 1px solid $c-primary-other;
    }
    
    @media only screen and (min-width: 330px) {
        margin-top: 0px;
        margin-left: 0px;
        margin-bottom: -7px;
    }
}

.contact-banner__text-container {
    padding: 30px 0;
    // margin: 0px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (min-width: 330px) {
        padding: 30px 0;
    }

    // :nth-child(3) {
    //     margin-bottom: 30px;
    // }

    p {
        margin: 0;
        color: $c-primary-other;
        text-align: left;
        font-family: $f-text;
        font-weight: bold;
        font-size: 13px;

        @media only screen and (min-width: 330px) {
            font-size: 16px;
        }
    }
}

.contact__social-icons {
    display: flex;

    img {
        margin-right: 5px;
    }
}

.contact {
    background-color: $c-primary-background;
    color: $c-secondary;
}

.form-container {
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 30px 15px;

    .contactme {
        display: flex;
        max-width: 600px;
        flex-direction: column;

        @supports (display: grid) {
        display: grid;
        grid-template-columns: 100px minmax(150px, 500px);
        grid-gap: 15px;
        }
    }

    input,
    textarea {
        border: 2px solid $c-secondary-dark;
        background-color: rgb(65, 65, 65);
        color: $c-text-light;
    }

    label {
        grid-column: 1 / 2;
    }
     
    input,
    button {
        grid-column: 2 / 3;
    }

    input[type=submit] {
        border: 1px solid $c-secondary-dark;
        cursor: pointer;
        max-width: 100px;
        padding: 10px 15px;
        margin-top: 7px;
        color: $c-secondary;

        @supports (display: grid) {
            margin-top: 0;
        }
        
       &:hover {
           background-color: $c-secondary;
           border: 1px solid $c-primary-other;
           color: $c-text-dark;
       }
    }
}

   



