.banner {
    background-image: radial-gradient(circle at 50% 80%, rgba(8, 8, 8, 0.2), transparent), url('/assets/images/banner/unity_banner_big.jpg');
    background-position: center, center;
    background-size: cover, cover;
    background-repeat: no-repeat, no-repeat;

    a {
        text-decoration: none;
        }
}

.banner--narrow {
    background-position: 100% 71%;
}

.banner__container {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.banner__container--narrow {
    max-height: 190px;
    max-width: $max-container-width;
    flex-direction: row;
    justify-content: flex-start;
}

.banner__heading {
    font-family: $f-heading;
    font-size: 80px;
    text-transform: uppercase;
    color: $c-text-light;
    padding: 0 0 0 15px;
}

.banner__image {
    margin: 60px 0 10px 0; 
}

// this query fixes problem with IE not scaling svg images correctly
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .banner__image[src*=".svg"] {
      width: 100%; 
    }
}

.banner__tagline {
    color: $c-text-light;
    font-family: 'Alegreya Sans';
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    margin: 30px 0;
    
    @media only screen and (min-width: 670px) {
        font-size: 40px;
    }
}


