.blog {
    background-color: $c-primary-background;
}

.next-post,
.previous-post {
    display: none;

    p {
        padding: 0;
        margin: 0;
    }

    @media #{$media-md} {
        display: inline-block;
    }
}

.blog__container {
    max-width: $max-container-width;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 30px 15px;
    justify-content: space-between;
    color: $c-text-light;
    min-height: 53vh;

    @media #{$media-md} {
        flex-direction: row;
    }
}

.category-navigation__container1 {
    margin-bottom: 30px;
    padding: 15px;
    box-shadow: 0 0 8px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);
    border-radius: 4px;
    border: 1px solid $c-tertiary-dark;
    order: -1;

    @media #{$media-md} {
        order: 5;
        margin-left: 30px;
        margin-bottom: 0;
    }
}

.category-navigation__container2 {

    @media #{$media-md} {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    right: 10px;
    }
}

.category-navigation__container3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin-bottom: 30px;

    @media #{$media-md} {
        flex-direction: column;
    }
}

.category__menu-item {
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 2px 6px;
    border-top: 1px solid rgb(163, 163, 163);
    border-left: 1px solid rgb(163, 163, 163);
    border-right: 1px solid rgb(163, 163, 163);
    border-bottom: 1px solid rgb(163, 163, 163);
    border-radius: 3px;
    

    @media #{$media-md} {
        white-space: nowrap;
        margin-right: 0;
        border-right: 0;
        border-top: 0;
        border-left: 0;
        border-radius: 0;
    }

    a {
        text-decoration: none;
        color: $c-text-light;

        &:hover,
        &:focus {
            color: $c-secondary;
        }
    }
}

.blog__heading {
    text-transform: uppercase;
    color: $c-secondary;
}

// category descriptions added by kocour
.blog__heading-subheader {
    color: #b6b6b6;
    font-size: 16px;
    font-style: italic;
    text-transform: none;
    display: none;
    margin: 0;

    @media #{$media-lg} {
        display: inline-block;
    }
}

.latest-posts__container {
    padding: 15px;
    box-shadow: 0 0 8px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);
    border-radius: 4px;
    border: 1px solid $c-tertiary-dark;
    margin-bottom: 30px;

    h4::after {
        display: block;
        content: '';
        border-bottom: 2px solid $c-secondary;
        margin: 10px 0;
        width: 33px;
    }
}

.blog-subscription {
    padding: 15px;
    box-shadow: 0 0 8px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);
    border-radius: 4px;
    border: 1px solid $c-tertiary-dark;
    margin-bottom: 30px;
}

.subscription-container {
    display: flex;

    input,
    textarea {
        border: 1px solid $c-text-light;
        background-color: rgb(65, 65, 65);
        color: $c-text-light;
        border-radius: 5px;
    }
}

.subscrition__form-heading {
    color: $c-tertiary;
}

#mce-EMAIL {
    margin-right: 10px;
    height: 40px;
    // border-radius: 5px;
    padding-left: 6px;
    // min-width: 300px;
}

.post__preview {
    display: flex;
    justify-content: space-between;
    
    a {
        text-decoration: none;
    }

    @media #{$media-md} {
        padding: 5px;
        font-size: 20px;
    }
}

.post-title {
    text-decoration: none;
    color: $c-text-light;

        &:hover,
        &:focus {
            color: $c-secondary;
        }
}

.related-posts {
    padding: 15px;
    box-shadow: 0 0 8px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);
    border-radius: 4px;
    border: 1px solid $c-secondary-dark;
    margin-top: 20px;
    margin-bottom: 30px;

    @media #{$media-md} {
        margin-top: 50px;
    }

    h5::after {
        display: block;
        content: '';
        border-bottom: 2px solid $c-secondary;
        margin: 10px 0;
        width: 33px;
    }
}

.related-posts__preview {
    display: flex;
    justify-content: space-between;
    
    a {
        text-decoration: none;
    }

    @media #{$media-md} {
        padding: 1px;
        font-size: 18px;
    }

}

.posts__container {
    display: flex;
    flex-direction: column;
    flex-basis: 90%;
    flex-shrink: 1;
    order: 0;

    a {
        color: $c-text-light;

        &:hover,
        &:focus {
            color: $c-secondary;
        }
    }
}

.all-posts__container {
    // display: none;
    padding: 15px;
    box-shadow: 0 0 8px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);
    border-radius: 4px;
    border: 1px solid $c-tertiary-dark;
    position: relative;

    h4::after {
        display: block;
        content: '';
        border-bottom: 2px solid $c-secondary;
        margin: 10px 0;
        width: 33px;
    }

    .back-to-top__container {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        bottom: 150px;
        right: 0;
        padding-right: 0;
        padding-bottom: 0;

        a {
            &:hover,
            &:focus {
                color: $c-text-dark;
            }
        }

        .button--tertiary1 {
            font-size: 15px;
            border: 1px solid rgb(136, 136, 136);
        }
    }
}

.post-list {
    list-style: none;
    padding-left: 15px;
}

.post-list__item {

    @media #{$media-md} {
        font-size: 17px;
    }

    a {
        text-decoration: none;
    }
}

.post-list__item-date {
    display: inline-block;
    color: rgb(163, 163, 163);
    font-style: italic;
    margin: 0;
}

// individual blogpost styling:
.blogpost {
    background-color: $c-primary-background;
}

.blogpost__container {
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 30px 15px;
    // display: flex;
    color: $c-text-light;
    min-height: 53vh;

    @media only screen and (min-width:1000px) {
        padding-right: 200px;
    }

    @media #{$media-md} {
        p {
            font-size: 18px;
        }
    }

    @media #{$media-lg} {
        p {
            font-size: 20px;
        }
    }
}

.blogpost__heading {
    color: $c-secondary;

    &::after {
        display: block;
        content: '';
        border-bottom: 2px solid $c-secondary;
        margin: 10px 0;
        width: 33px;
    }
}

.blogpost__date {
    font-size: 14px !important;
}

.blogpost__video-container {
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
    

.blogpost__image {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 0 3px rgb(15, 15, 15);
    background-color: $c-primary-background;

    @media #{$media-lg} {
        max-width: 49%;
        height: auto;
        transition: all 0.9s ease-in-out;

        &:hover {
            border-radius: 3px;
            transform: scale(1.9);
        }
    }
}

.blogpost__image__mobile_screenshot {
    width: 20%;
    margin-bottom: 20px;
    box-shadow: 0 0 3px rgb(15, 15, 15);
    background-color: $c-primary-background;

    @media #{$media-lg} {
        max-width: 20%;
        height: auto;
        margin-right: 2px;
        transition: all 0.9s ease-in-out;

        &:hover {
            border-radius: 3px;
            transform: scale(1.5);
        }
    }
}

.blogpost__buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blogpost__gallery {
    margin-top: 30px;

    @media #{$media-lg} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    // @supports (display: grid) {
    //     display: grid;
    //     grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    //     // grid-template-rows: 400px;
    //     grid-gap: 30px;
    //     object-fit: unset;
    //     justify-content: unset;
    //     align-items: unset;
    // }
}

code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    background: transparent;
    border: 0;
    white-space: pre-wrap;

    @media #{$media-lg} {
        white-space: unset;
    }
}

.blogpost__anchor {
    text-decoration: none;
    color: $c-tertiary;

    &:hover {
        cursor: pointer;
        color: $c-secondary;
    }
}

.blogpost__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 15px;
    border-bottom: 2px solid #5e5d5d;

    a {
        display: inline-block;
        color: $c-text-light;
        text-decoration: none;
        padding-top: 10px;

        &:hover,
        &:focus {
            color: $c-secondary;
        }
    }

    // @media #{$media-lg} {
    //     padding-bottom: unset;
    // }
}

