.footer {
    background-color: $c-secondary;
    color: $c-primary-background;
}

.footer--games-theme {
    background-color: $c-tertiary;
}

.footer__container {
    color: $c-primary-other;
    font-family: $f-heading;
    font-size: 12px;
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 40px 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    @media only screen and (min-width: 760px) {
        flex-wrap: nowrap;
        font-weight: bold;
        font-size: 16px;
    }
}

.footer__item-wrapper--centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer__item-wrapper {
    margin-bottom: 20px;
}

.footer__column1-1,
.footer__column2-1,
.footer__column3-1 {
    margin-bottom: 5px;
}

.footer__column3-2 {
    display: flex;

    img {
        margin-right: 10px;
        width: 18px;
    }
}

.footer2 {
    background-color: $c-primary-background;
}

.footer3 {
    display: flex;
    justify-content:space-between;
    max-width: $max-container-width;
    margin: 0 auto;
}

.footer__design-by {
    // max-width: $max-container-width;
    // display: flex;
    // margin: auto;
    padding: 7px 15px;
    font-size: 11px;
    color: $c-text-mid-dark;

    a {
        text-decoration: none;
        margin-left: 5px;
        color: $c-secondary;
    }

    p {
        margin: 4px 0;
    }

    @media #{$media-md} {
        font-size: 14px;
    }
}

.footer__powered-by {
    // max-width: $max-container-width;
    // margin: auto;
    font-size: 11px;
    color: $c-text-mid-dark;
    font-size: 11px;
    // margin: 0 auto;
    padding: 7px 15px;

    a {
        text-decoration: none;
        margin-right: 5px;
        color: $c-secondary;
    }

    p {
        margin: 4px 0;
    }

    @media #{$media-md} {
        font-size: 14px;
    }
}

.footer__design-by--games-theme {

    a {
        color: $c-tertiary;
    }
}