.portfolio-homepage {
    background-color: $c-primary-background;
    padding-bottom: 30px;
}
  
.local-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    @media #{$media-lg} {
        // padding-top: 45px;
        // position: -webkit-sticky; /* Safari */
        // position: sticky;
        position: fixed;
        right: 7px;
        top: 55px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: none;
    }
}

.local-nav__link {
    font-family: $f-heading;
    text-transform: uppercase;
    background-color: $c-primary-other;
    border: 1px solid rgb(141, 141, 141);
    color: $c-text-mid-dark;
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    margin-right: 7px;
    margin-bottom: 7px;
    font-size: 14px;
    white-space: nowrap;

    &:active,
    &:hover {
        background-color: $c-tertiary;
        color: $c-text-dark;
        border: 1px solid $c-text-dark;
    }

    @media #{$media-lg} {
        display: block;
        margin-bottom: 5px;
        width: 100%;
        font-size: 16px;
    }
}

.local-nav__link--sw-theme {

    &:active,
    &:hover {
        background-color: $c-secondary;
    }
}

//this is to replace the submenu items in mobile menu with buttons and hide them for large screens
.portfolio-homepage__local-nav {
    @media #{$media-md} {
        display: none;
    }
}

.local-nav2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    width: 100%;

    @media #{$media-lg} {
        flex-direction: column;
        align-items: flex-start;
    }
}

.local-nav__link2 {
    font-family: $f-heading;
    text-transform: uppercase;
    background-color: $c-primary-other;
    color: $c-text-mid-dark;
    border: 1px solid rgb(141, 141, 141);
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    margin-right: 7px;
    font-size: 13px;
    white-space: nowrap;

        &:active,
        &:hover {
        background-color: $c-tertiary;
        color: $c-text-dark;
        border: 1px solid $c-text-dark;
        }

    @media #{$media-lg} {
        display: block;
        margin-bottom: 5px;
        width: 100%;
        font-size: 16px;
        padding: 8px 3px 8px 12px;
    }
}

.local-nav__link2--sw-theme {
    
    &:active,
    &:hover {
    background-color: $c-secondary;
    }
}

.local-nav__back-to-top {
    display: none;

    @media #{$media-lg} {
        display: block;
    }
}

.portfolio-homepage__heading1 {
    max-width: $max-container-width;
    background-color: $c-primary-background;
    padding: 30px 15px;
    margin: 0 auto;
    color: $c-secondary;
    text-transform: uppercase;

    h2::after {
            display: block;
            content: '';
            border-bottom: 3px solid $c-secondary;
            margin: 15px 0;
            width: 47px;
    }
}

.portfolio-homepage__heading2 {
    max-width: $max-container-width;
    background-color: $c-primary-background;
    padding: 30px 15px;
    margin: 0 auto;
    color: $c-tertiary;
    text-transform: uppercase;

    h2::after {
            display: block;
            content: '';
            border-bottom: 3px solid $c-tertiary;
            margin: 15px 0;
            width: 47px;
    }
}

.portfolio-homepage__container {
    max-width: $max-container-width;
    margin: 0 auto;
    padding-bottom: 40px;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 360px));
    grid-gap: 30px;
    justify-content: flex-start;
    }
    
}

.portfolio-homepage__buttons {
    display: flex;
    max-width: 500px;
    justify-content: space-between;
    padding: 30px 0;
}

.portfolio__card {
    display: flex;
    max-width: 360px;
    flex-direction: column;
    color: $c-text-light;
    border: 1.5px solid $c-text-mid-dark;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 7px rgb(15, 15, 15);
    margin-bottom: 30px;

    @supports (display: grid) {
        margin-bottom: 0;
    }
}

// this is for mobile version so that the button is displayed all the time
.read-more {
    z-index: 1;
    display: block;
    position: absolute;
    font-size: 14px;
    color: $c-text-light;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid $c-text-light;
    border-radius: 4px;
    padding: 4px 6px;
    background-color: $c-primary-other;
    top: 170px;
    left:35%;

    @media #{$media-md} {
        font-size: 26px;
    }
}

.portfolio__preview-image-wrapper {
    max-height: 230px;
    overflow: hidden;
    position: relative;

    @media #{$media-md} {
        &>.read-more {
            display: none;
        }

        &:hover >.read-more,
        &:active >.read-more {
            position: absolute;
            display: block;
            top: 72px;
            left:30%;
            font-size: 24px;
            color: $c-text-light;
            background-color: transparent;
            text-align: center;
            text-transform: uppercase;
            border: 2px solid $c-text-light;
            border-radius: 4px;
            padding: 6px 9px;
        }
    }  
}

.read-more--hover {
        position: absolute;
        display: block;
        opacity: 1;
        top: 72px;
        left:30%;
        font-size: 24px;
        color: $c-text-light;
        text-align: center;
        text-transform: uppercase;
        border: 2px solid $c-text-light;
        border-radius: 4px;
        padding: 6px 9px;
}

.portfolio__preview-image {
    width: 100%;
    object-fit: cover;
    transition: all 600ms cubic-bezier(0.735, -0.055, 0.365, 1.250);

    &:hover {
    transform: scale(1.7);
    filter: brightness(.3);
    }
}

.p-triggering-hover:hover ~ .img-triggered-from-p,
.portfolio__preview-image--hover {
    transform: scale(1.7);
    filter: brightness(.3);
}


.portfolio__preview-name,
.portfolio__preview-description,
.portfolio__preview-technology,
.portfolio__preview-button {
    margin: 15px;
    font-size: 12px;

    @media #{$media-md} {
        font-size: 15px;
    }
}

.portfolio__preview-name {
    text-transform: uppercase;
}

.portfolio__card-button-wrapper {
    margin: 15px auto;
}

.portfolio__details-container {
    max-width: $max-container-width;
    margin: 0 auto;
    color: $c-text-light;
    padding-top: 50px;
    padding-bottom: 50px;
}

.portfolio__details-card {
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(138, 138, 138);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 7px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);


    @media #{$media-lg} {
        padding: 30px;
        box-shadow: 0px 0px 10px rgb(15, 15, 15);
    }
}

.portfolio__details-card-heading {
    text-transform: uppercase;
    color: $c-secondary;

    h3::after {
        display: block;
        content: '';
        border-bottom: 2px solid $c-secondary;
        margin: 10px 0;
        width: 33px;
    }
}

.portfolio__details-card-heading--games-theme {
    color: $c-tertiary;

    h3::after {
        border-bottom: 2px solid $c-tertiary;
    }
}

.portfolio__details-card-technology {
    margin: 15px 0;

    @media #{$media-lg} {
        font-size: 18px;
    }
}

.portfolio__details-card-description {
    margin: 15px 0;
    
    @media #{$media-lg} {
        padding-right: 200px;
        text-align: justify;
        font-size: 18px;
    }
}

.portfolio__details-card-buttons {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    // align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    &> .button {
        margin: 10px 10px 10px 0;
    }
   
    img {
        vertical-align: bottom;
        margin-right: 10px;
    }

    @media #{$media-md} {
        flex-direction: row;
        align-items: center;

        &> .button {
            margin: 10px 10px 10px 0;
        }
    }

    @supports (display: grid) {
        justify-content: flex-start;
    }
}

.portfolio__details-card-video-container {
    display: inline-block;
    flex-shrink: 1;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    margin-bottom: 20px;
    
    @media #{$media-lg} {
        margin-right: 20px;
    }
}

.youtube-player {
    position: relative;
    padding-top: 55.0%;
    /* Use 75% for 4:3 videos */
    height: 0;
    overflow: hidden;
    max-width: 98%;
    background: $c-text-dark;
    margin-bottom: 25px;

    @media #{$media-lg} {
        margin-right: 25px;
    }
}

.youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
    overflow: hidden;
}

.youtube-player img {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    transition: .4s all;
}

.youtube-player img:hover {
  filter: brightness(75%);
}

.youtube-player .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url("//i.imgur.com/TxzC70f.png") no-repeat;
    cursor: pointer;
}

.portfolio__details-card-gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 340px));
    grid-gap: 20px;
    // justify-content: space-evenly;
    grid-auto-flow:row;
    grid-auto-flow: row;
    }
    
    img {
        max-height: 300px;
        box-shadow: 0px 0px 3px rgb(15, 15, 15);
        background-color: $c-primary-background;
        vertical-align: bottom;

        @supports (display: grid) {
        // height: 100%;
        width: 100%;
        object-fit: contain;
        }

        @media #{$media-lg} {
            transition: all 0.8s ease-in-out;
    
            &:hover {
                border-radius: 3px;
                transform: scale(1.9);
                object-fit: contain;
                background-color: $c-primary-background;
            }
        }
    }
}

.portfolio__details-card-gist {
    display: none;
    
    @media #{$media-md} {
    display: block;
    max-height: 300px;
    overflow-y: scroll;
    margin-top: 30px;
    margin-bottom: 10px;
    border: 1px solid rgb(95, 95, 95);
    }
}

