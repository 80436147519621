.container {
    padding: 0 10px;

    @media #{$media-md} {
        padding: 0 15px;
    }
}

// .section--light {
//     background-color: #f5f5f5;
// }


// .container {
//     width: 100%;
//     max-width: 1200px;
//     margin: 0 auto;
//     padding-left: 15px;
//     padding-right: 15px;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
// }

// .container--align-center {
//     align-items: center;
// }

// .container__col-half {
//     flex: 0 0 100%;

//     @media #{$media-md} {
//     flex: 0 0 calc((100% - 30px) / 2);
//     }
// }

// .container__col-third {
//     flex: 0 0 100%;

//     @media #{$media-md} {
//     flex: 0 0 calc((100% - 60px) / 3);
//     }
// }

// .container__col-quarter {
//     flex: 0 0 100%;

//     @media only screen and (min-width: 450px) {
//     flex: 0 0 calc((100% - 30px) / 2);
//     }

//     @media only screen and (min-width: 900px) {
//         flex: 0 0 calc((100% - 90px) / 4);
//     }
// }