.resume {
    background-color: $c-primary-background;
    color: $c-text-light;
    padding-bottom: 30px;
}

.resume__comments {
    font-size: 17px;
    // color: $c-secondary;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 2px solid $c-secondary-dark;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 0px 5px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);
    margin-bottom: 30px;

    @media only screen and (min-width: 900px) {
        // margin-bottom: 0;
    }

    .button {
        margin-right: 10px;
    }
}

.career-card__text-container {
    display: flex;
    flex-direction: column;
    border: 2px solid $c-secondary-dark;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 7px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);

    h4 {
        margin-bottom: 15px;
        text-transform: uppercase;
        color: $c-secondary;
    }

    @media only screen and (min-width: 900px) {
        margin-bottom: 0;
    }
}

.career-card__table {
    display: flex;
}

.career-card__table-date {
    min-width: 170px;
}

.small-card {
    display: flex;
    flex-direction: column;
    padding: 15px;
    color: $c-text-light;
    border: 2px solid $c-secondary-dark;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(15, 15, 15);
    background-color: rgb(65, 65, 65);
    margin-bottom: 30px;
    
    li {
    list-style: none;
    }

    ul {
        padding-left: 0;
    }

    h4 {
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $c-secondary;
    }

    h5 {
        text-transform: uppercase;
    }

    @media only screen and (min-width: 900px) {
        margin-bottom: 0;
    }
}

.resume__container {
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;

    @media #{$media-md} {
        padding: 30px 15px;
        line-height: 1.8;
    }
    
    @supports (display: grid) {
        display: grid;
    }

    @media only screen and (min-width: 900px) {
        display: grid;
        grid-template-columns: 3fr 1fr;
        // grid-auto-rows: minmax(100px, 400px);
        // grid-template-rows: repeat(autofit (minmax(150px, 350px)));
        grid-gap: 30px;

        .resume__comments {
            margin-bottom: 30px;

            @supports (display: grid) {
            margin-bottom: 0;
            grid-column: 1 / 3;
            grid-row: 1 / 2;
            }
        }

        .career-card__text-container {
            margin-bottom: 30px;

            @supports (display: grid) {
            margin-bottom: 0;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            }
        }
        
        .skills-card {
            margin-bottom: 30px;

            @supports (display: grid) {
            margin-bottom: 0;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            }
        }

        .languages-card {
            margin-bottom: 30px;

            @supports (display: grid) {
            margin-bottom: 0;
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            } 
        }

        .interests-card {
            margin-bottom: 30px;

            @supports (display: grid) {
            margin-bottom: 0;
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            }
        }

        // this is courses container with heading, the whole element is here part of resume container grid
        .courses-card__container1 {
            h4 {
                margin-bottom: 30px;
            }

            @supports (display: grid) {
            margin-bottom: 0;
            grid-column: 1 / 3;
            grid-row: 4 / 5;
            }
        }
    }
}

// this is courses container without heading, the whole element contains its own grid
.courses-card__container2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @supports (display: grid) {
        @media only screen and (min-width: 900px) {
            display: grid;
            grid-template-columns: repeat(3, minmax(200px, 400px));
            grid-gap: 15px;
        }
    }
}

.course-card {
    border: 1px dotted $c-secondary;
    background-color: #4B4B4B;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (min-width: 900px) {
        border: 0;
        box-shadow: 0px 0px 4px rgb(34, 34, 34);
    }
    
   
    @supports (display: grid) {
        margin-bottom: 0;
    }

    h5 {
        color: $c-secondary;
    }

    a {
        color: $c-text-light;
    }

    h5,
    p,
    a {
        margin: 0;
    }
}

.certificate-picture__wrapper {
    margin-top: 10px;
}

.certificate-picture {
    display: none;
    max-height: 180px;
    vertical-align: bottom;
    transition: all 1s ease-in-out;

    @media only screen and (min-width: 700px) {
        display: block;
        cursor: pointer;
    }

    &:hover {
        transform: scale(2.5);
        border-radius: 1px;
        overflow: hdden;

        @media only screen and (min-width: 700px) {
            transform: scale(3.2);
        }
    }
}